import { MembersApi } from '@wix/social-groups-api/dist/src/services/members/MembersApi';
import { GroupMembersApi } from '@wix/social-groups-api/dist/src/services/group-members/GroupMembersApi';
import { EventsApi } from '@wix/social-groups-api/dist/src/services/events/EventsApi';
import { NotificationsApi } from './notification-settings/NotificationsApi';
import { IApiContext } from '@wix/social-groups-api/dist/src/services/IApiContext';
import { GroupService } from '@wix/social-groups-api/dist/src/services/group/GroupService';
import { IApiDelegate } from '@wix/social-groups-api/dist/src/services/IApiDelegate';
import { MembersService } from '@wix/social-groups-api/dist/src/services/members/MembersService';
import { GroupAppsApi } from '@wix/social-groups-api/dist/src/services/group-apps/GroupAppsApi';
import { GroupsAppSettings } from '@wix/social-groups-api/dist/src/services/groups-app-settings/GroupsAppSettings';

export class ApiDelegate implements IApiDelegate {
  private static instance: ApiDelegate;
  private readonly members: MembersApi;
  private readonly membersService: MembersService;
  private readonly groupMembers: GroupMembersApi;
  private readonly events: EventsApi;
  private readonly group: GroupService;
  private readonly notificationSettings: NotificationsApi;
  private readonly groupApps: GroupAppsApi;
  private readonly groupAppSettings: GroupsAppSettings;

  static getInstance(context: IApiContext) {
    if (!ApiDelegate.instance) {
      ApiDelegate.instance = new ApiDelegate(context);
    }
    return ApiDelegate.instance;
  }

  private constructor(private readonly context: IApiContext) {
    this.group = new GroupService(this.context);
    this.members = new MembersApi(this.context);
    this.groupMembers = new GroupMembersApi(this.context);
    this.events = new EventsApi(this.context);
    this.notificationSettings = new NotificationsApi(this.context);
    this.membersService = new MembersService(this.members);
    this.groupApps = new GroupAppsApi(this.context);
    this.groupAppSettings = new GroupsAppSettings(this.context);
  }

  getGroupsAppSettings(): GroupsAppSettings {
    return this.groupAppSettings;
  }

  getGroupAppsApi(): GroupAppsApi {
    return this.groupApps;
  }

  getGroupApi() {
    return this.group;
  }

  getMembersApi() {
    return this.members;
  }

  getGroupMembersApi() {
    return this.groupMembers;
  }

  getEventsApi() {
    return this.events;
  }

  getNotificationsApi() {
    return this.notificationSettings;
  }

  getMembersService(): MembersService {
    return this.membersService;
  }
}
